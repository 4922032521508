.x-pagination
  display: flex
  align-items: center
  font-size: 13px
  line-height: 18px
  letter-spacing: -0.371429px
  color: var(--on-bg-dark)
  &__total
    margin-right: 8px
  &__limit
    margin-left: 16px
  &__page
    width: 40px
  &__current
    margin-left: 8px
  &__nav
    display: flex
    margin-left: 24px
    button
      display: flex
      align-items: center
      justify-content: center
      width: 40px
      height: 40px
      background: var(--button-static-white)
      border: 1px solid var(--button-static-dark-grey)
      color: var(--icon-dark-grey)
      position: relative
      &:first-child
        border-radius: var(--base-radius) 0 0 var(--base-radius)
      &:last-child
        border-radius: 0 var(--base-radius) var(--base-radius) 0
      &:before
        content: ""
        display: block
        width: 0
        height: 0
        border-style: solid
        border-width: 8px 8px 8px 0
        border-color: transparent currentColor transparent transparent
      + button
        margin-left: -1px
        &:before
          transform: rotate(180deg)
      &:hover
        border-color: var(--button-hover-green)
        color: var(--icon-hover-primary)
        z-index: 2
      &[disabled]
        color: var(--button-static-dark-grey) !important
        border-color: var(--button-static-dark-grey) !important
        background: var(--button-disabled) !important
        cursor: not-allowed
  &[disabled]
    color: var(--button-static-dark-grey)
