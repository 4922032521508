import { Component, Host, h, Prop } from '@stencil/core';
import { declOfNum, translate } from '../../utils';
import { paginationStore } from '../../stores';

const presets = [20, 50, 100];

const t = translate({
  en: {
    of: 'of',
    entries: ['record', 'records', 'records'],
  },
  ru: {
    of: 'из',
    entries: ['записи', 'записей', 'записей'],
  },
});

@Component({
  tag: 'x-pagination',
  styleUrl: 'x-pagination.sass',
})
export class XPagination {
  @Prop() disabled: boolean = false;

  private updateSize = e => {
    paginationStore.set('pageSize', parseInt(e.target.value, 10));
  };

  private updateCurrent = e => {
    const nextPage = parseInt(e.target.value, 10);

    if (isNaN(nextPage)) {
      return;
    }

    paginationStore.set('currentPage', parseInt(e.target.value, 10));
  };

  private prev = () => {
    paginationStore.set('currentPage', paginationStore.get('currentPage') - 1);
  };

  private next = () => {
    paginationStore.set('currentPage', paginationStore.get('currentPage') + 1);
  };

  render() {
    const currentPage = paginationStore.get('currentPage');
    const pageSize = paginationStore.get('pageSize');
    const totalPages = paginationStore.get('totalPages');
    const totalRecords = paginationStore.get('totalRecords');

    return (
      <Host class="x-pagination">
        <div class="x-pagination__total">
          {currentPage * pageSize - pageSize + 1}-{Math.min(currentPage * pageSize, totalRecords)} {t`of`} {totalRecords} {declOfNum(totalRecords, t('entries'))}
        </div>
        <div class="x-pagination__page">
          <x-input type="text" value={currentPage} align="center" mask="currentPage" onChange={this.updateCurrent} disabled={this.disabled}></x-input>
        </div>
        <div class="x-pagination__current">
          {t`of`} {totalPages}
        </div>
        <div class="x-pagination__limit">
          <x-toggle-button group size="lg" name="limit" onChange={this.updateSize}>
            <x-grid container gap="3xs">
              {presets.map(preset => (
                <x-grid item size="52px">
                  <x-toggle-button value={preset} color="white-20" selected={pageSize === preset} disabled={this.disabled}>
                    {preset}
                  </x-toggle-button>
                </x-grid>
              ))}
            </x-grid>
          </x-toggle-button>
        </div>
        <div class="x-pagination__nav">
          <button type="button" onClick={this.prev} disabled={this.disabled || currentPage === 1}></button>
          <button type="button" onClick={this.next} disabled={this.disabled || currentPage === totalPages}></button>
        </div>
      </Host>
    );
  }
}
